import axiosService from 'axios'
import { getCsrfToken } from 'shared/helpers/CsrfTokenHelper'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'

export const REQUEST_METHODS = {
  get: 'get',
  post: 'post'
}

const SIGNIN_ROUTE = '/signin'
const PARTIAL_CONTENT = 206
const UNAUTHORIZED = 401
const GONE = 410
const DEFAULT_HANDLERS = {
  unAuthorized: onUnauthorized
}

let abortController

export function withPartialContent(apiCall) {
  return (params) => {
    return new Promise((resolve, reject) => {
      apiCall(params)
        .then(({ status, data }) => {
          if (status === PARTIAL_CONTENT) {
            const abortSignal = params.abortSignal || new AbortController().signal
            setTimeout(() => {
              withPartialContent(apiCall)({
                ...params,
                guid: data.guid
              }, { signal: abortSignal })
                .then(resolve)
                .catch(reject)
            }, 2000)
          } else {
            resolve(data)
          }
        })
        .catch(error => reject(error))
    })
  }
}

// cancel previous request if same token
export function withCancellation(method, url, params) {
  abortController && abortController.abort()

  abortController = new AbortController()
  const signal = abortController.signal

  const methodParams = () => {
    switch (method) {
      case REQUEST_METHODS.get:
        return params
      case REQUEST_METHODS.post:
        return { data: params }
    }
  }

  return Axios.service({ method, url, ...methodParams(), signal })
}

function onUnauthorized(error) {
  window.location = get(error, 'response.data.redirect_url') || SIGNIN_ROUTE
}

const Axios = (() => {
  const csrfToken = getCsrfToken()
  let service = null
  const headers = {
    'X-Requested-With': 'XMLHttpRequest',
    'X-CSRF-Token': csrfToken
  }
  let options = {}

  const handleError = (error) => {
    switch (get(error, 'response.status')) {
      case UNAUTHORIZED:
        service.handlers.unAuthorized && service.handlers.unAuthorized(error)
        break
      case GONE:
        window.location.reload()
        break
    }

    return Promise.reject(error)
  }

  const handleSuccess = (response) => {
    response.headers.authorization &&
      FollozeState.setJwtToken(response.headers.authorization.replace('bearer ', ''))

    return response
  }

  function getJwtToken() {
    if (typeof (FollozeState) === 'undefined') {
      return
    }
    return !isEmpty(FollozeState.getJwtToken())
      ? `bearer ${FollozeState.getJwtToken()}`
      : null
  }

  service = axiosService.create({
    xsrfHeaderName: 'X-CSRF-Token',
    headers
  })
  service.handlers = DEFAULT_HANDLERS
  service.setHandlers = (handlers) => {
    service.handlers = { ...service.handlers, ...handlers }
  }
  service.interceptors.response.use(handleSuccess, handleError)
  service.interceptors.request.use((config) => {
    if (options.getGuid) {
      config.headers['folloze-session-guid'] = options.getGuid()
    }

    const jwtToken = getJwtToken()

    if (!!jwtToken && !config.noBearer) {
      config.headers.Authorization = jwtToken
    }

    return config
  })

  return {
    setOptions: (newOptions = {}) => {
      options = {
        ...options,
        ...newOptions
      }
    },
    service
  }
})()

export const setOptions = Axios.setOptions
export const axios = Axios.service
